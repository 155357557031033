import { createStyles, Fade, List, ListItem, ListItemText, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MapLinkComp from "../demo/MapLinkComp";
import ViewMapLinkComp from "../demo/page/ViewMapLinkComp";
import { formatDate } from "../Util";
import MapWaitlistData from "./MapWaitlistData";

export interface MapTableCompProps {
  jwt: string | null,
  maps: MapWaitlistData[],
  set_downloading: (state: boolean) => void
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsWrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1)
    },
  })
);

export default function MapTableComp(props: MapTableCompProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return <List>
    {props.maps.length === 0 &&
      <Fade in timeout={500} unmountOnExit>
        <Typography>
          0 maps :(
        </Typography>
      </Fade>
    }

    {props.maps
      .sort((a, b) => a.upload_date < b.upload_date ? 1 : -1)
      .map((map, index) =>
        <Fade in timeout={500} unmountOnExit key={map.name}>
          <ListItem
            button
            style={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <ListItemText
              primary={
                <div
                  className={classes.itemsWrapper}
                >
                  {index + 1}.

                  <MapLinkComp
                    map={map.name}
                    jwt={props.jwt}
                    set_downloading={props.set_downloading}
                    on_unauthorized={() => { }}
                  />

                  <ViewMapLinkComp
                    map={map.name}
                    jwt={props.jwt}
                    set_downloading={props.set_downloading}
                    on_unauthorized={() => { }}
                  />

                  <Typography>
                    {t("maps.released_on")}

                    <i>
                      {formatDate(map.upload_date)}
                    </i>
                  </Typography>
                </div>
              }
            />
          </ListItem>
        </Fade>
      )}
  </List>;
};
