import { createStyles, /* Dialog, IconButton, */ Link, makeStyles, Theme, Tooltip, useTheme } from "@material-ui/core";
import LiveTvIcon from '@material-ui/icons/LiveTv';
import React from "react";
import { useTranslation } from "react-i18next";

import { API_URL } from "../Config";
// import { initHLViewer } from "../player";

export interface ViewDemoLinkProps {
  jwt: string | null,
  demo: {
    demoMD5?: string,
    map?: string,
    player?: string,
    time?: number,
  },
  xj?: boolean,
  cosy?: boolean,
  lj?: boolean,
  serverRecord?: boolean,
  on_unauthorized?: () => void,
  on_click?: () => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      transition: "color 0.5s",
      transitionTimingFunction: "ease-in-out",
      cursor: "grabbing"
    },
  })
);

export default function ViewDemoLink(props: ViewDemoLinkProps) {
  const classes = useStyles();
  const { jwt, demo, lj, xj, cosy, on_unauthorized, on_click } = props;
  const { t } = useTranslation();

  const theme = useTheme();
  const [hovering, set_hovering] = React.useState(false);
  // const [playerDialogOpen, set_playerDialogOpen] = React.useState(false);

  const makeLink = React.useCallback(() => {
    if (!jwt) {
      return "";
    }

    // return "#";

    if (xj) {
      return `${API_URL}/viewDemo/XJ/${jwt}/${demo.map}/${demo.player}/${demo.time}`;
    }

    if (cosy) {
      return `${API_URL}/viewDemo/COSY/${jwt}/${demo.map}/${demo.player}/${demo.time}`;
    }

    if (!props.serverRecord) {
      return `${API_URL}/viewDemo/${lj ? 1 : 0}/${jwt}/${demo.demoMD5}`;
    }

    return `${API_URL}/viewServerRecord/${jwt}/${demo.demoMD5}`;
  }, [jwt, demo, lj, xj, cosy, props.serverRecord]);

  // const hlv = React.useMemo(() => initHLViewer('#hlv-target', "mybase"), []);

  // React.useEffect(() => {
  //   hlv?.load("mydemo");
  // }, [hlv]);

  if (xj || cosy) {
    return null;
  }

  return <div>
    <Tooltip title={`${t("viewdemo.title")}`}>
      {/* <IconButton
        onClick={() => set_playerDialogOpen(true)}
      >
        <LiveTvIcon fontSize="small" />
      </IconButton> */}

    <Link
      target="_blank"
      className={classes.link}
      style={{
        color: hovering ? theme.palette.error.dark : theme.palette.text.primary
      }}
      href={makeLink()}
      onClick={() => {
        if (!jwt) {
          on_unauthorized && on_unauthorized();
          return;
        }

        on_click && on_click();
      }}
      onMouseEnter={() => set_hovering(true)}
      onMouseLeave={() => set_hovering(false)}
    >
      <LiveTvIcon fontSize="small" />

      {/* <Dialog
        open={playerDialogOpen}
        onClose={() => set_playerDialogOpen(false)}
      >
        <div>
          HELLO I AM DIALOG
        </div>
      </Dialog> */}
    </Link>
    </Tooltip>

    {/* <Dialog
      open={playerDialogOpen}
      onClose={() => set_playerDialogOpen(false)}
    >
      <div id="hlv-target" style={{
        width: "800px",
        height: "600px"
      }}>
        HELLO I AM VIDEO
      </div>
    </Dialog> */}
  </div>;
};
