import React from "react";
import { createStyles, FormControlLabel, List, ListItem, ListItemText, makeStyles, Switch, TextField, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

import AuthorLink from "../user/AuthorLink";
import User from "../user/User";

import DemoWaitlistData from "./DemoWaitlistData";
import { formatDate } from "../Util";
import ViewDemoLink from "../viewdemo/ViewDemoLink";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MapLinkComp from "./MapLinkComp";
import DemoTimeLinkComp from "./DemoTimeLinkComp";
import { useTranslation } from "react-i18next";
import { wpnToString } from "./Weapon";
import DemoWaitlistStatus from "./DemoWaitlistStatus";
import AnalyzeLink from "../challenge/AnalyzeLink";
import ViewMapLinkComp from "./page/ViewMapLinkComp";
import MapDownloadLinkComp from "./MapDownloadLinkComp";

export interface DemosTableCompProps {
  user: User | undefined,
  demos: DemoWaitlistData[],
  jwt: string | null,
  showPlayer: boolean,
  showActiveSwitch: boolean,
  set_downloading: (state: boolean) => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsWrapper: {
      display: "grid",
      alignItems: "baseline",
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1)
    },
    analysisLink: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      cursor: "grabbing"
    },
  })
);

export default function DemosTableComp(props: DemosTableCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [sortField, set_sortField] = React.useState("map");
  const [sortAscending, set_sortAscending] = React.useState(true);
  const [filterText, set_filterText] = React.useState("");
  const [onlyActiveDemos, set_onlyActiveDemos] = React.useState(false);

  const sortFunc = React.useCallback((a: DemoWaitlistData, b: DemoWaitlistData) => {
    const factor = (sortAscending ? 1 : -1);

    if (sortField === "map") {
      return a.map.localeCompare(b.map) * factor;
    }

    if (sortField === "player") {
      return a.uploader!.displayName.localeCompare(b.uploader!.displayName) * factor;
    }

    if (sortField === "time") {
      return (a.time < b.time ? -1 : 1) * factor;
    }

    if (sortField === "weapon") {
      return wpnToString(a.weapon).toLowerCase().localeCompare(wpnToString(b.weapon).toLowerCase()) * factor;
    }

    return (a.uploadDate < b.uploadDate ? -1 : 1) * factor;
  }, [sortField, sortAscending]);

  const getSortingIcon = React.useCallback((field: string) => {
    if (field !== sortField) {
      return null;
    }

    if (sortAscending) {
      return <ArrowUpwardIcon />;
    }

    return <ArrowDownwardIcon />;
  }, [sortAscending, sortField]);

  const filter = React.useCallback((a: DemoWaitlistData) => {
    if (filterText.length === 0) {
      return true;
    }

    const filters = filterText.split(',');

    let matches = false;

    for (const localFilter of filters) {
      if (localFilter.length === 0) {
        continue;
      }

      if (a.uploader?.displayName.toLowerCase().indexOf(localFilter) !== -1) {
        matches = true;
        continue;
      } else if (a.map.toLowerCase().indexOf(localFilter) !== -1) {
        matches = true;
        continue;
      } else if (wpnToString(a.weapon).toLowerCase().indexOf(localFilter) !== -1) {
        matches = true;
        continue;
      } else {
        matches = false;
      }
    }

    return matches;
  }, [filterText]);

  const filteredDemos = props.demos.filter(filter)
    .filter(demo => !onlyActiveDemos || demo.status !== DemoWaitlistStatus.Beat);

  const gridColumns = props.showPlayer ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr";

  return <List>
    <div>
      <TextField
        value={filterText}
        onChange={(e) => {
          set_filterText(e.target.value);
        }}
        autoComplete="off"
        variant="outlined"
        fullWidth
        label={props.showPlayer ? t("demo.table.filter.map_player") : t("demo.table.filter.map")}
        style={{
          paddingBottom: theme.spacing(2)
        }}
      />

      {props.showActiveSwitch &&
        <FormControlLabel
          control={
            <Switch
              checked={onlyActiveDemos}
              onChange={(e) => set_onlyActiveDemos(e.target.checked)}
            />
          }
          label={t("demo.table.only_active")}
        />
      }
    </div>

    {filteredDemos.length === 0 && t("demo.table.no_demos")}

    {filteredDemos.length !== 0 &&
      <>
        <ListItem
          button
          style={{
            backgroundColor: theme.palette.background.default
          }}
        >
          <ListItemText
            primary={
              <div
                className={classes.itemsWrapper}
                style={{
                  gridTemplateColumns: gridColumns
                }}
              >
                {[
                  ["map", t("demo.table.columns.map")],
                  ["player", t("demo.table.columns.player")],
                  ["time", t("demo.table.columns.time")],
                  ["weapon", t("demo.table.columns.weapon")],
                  ["date", t("demo.table.columns.date")],
                ].filter(([field]) => props.showPlayer || field !== "player")
                  .map(([field, label], index) =>
                    <div
                      key={index}
                      className={classes.itemWrapper}
                      onClick={() => {
                        set_sortField(field);
                        set_sortAscending(!sortAscending);
                      }}
                    >
                      <Typography>
                        {label}
                      </Typography>

                      {getSortingIcon(field)}
                    </div>
                  )}
              </div>
            }
          />
        </ListItem>

        {filteredDemos.sort(sortFunc).map((demo, index) =>
          <ListItem
            button
            key={index}
            style={{
              height: theme.spacing(10)
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <div
                  className={classes.itemsWrapper}
                  style={{
                    gridTemplateColumns: gridColumns
                  }}
                >
                  <div className={classes.itemWrapper}>
                    <MapLinkComp
                      jwt={props.jwt}
                      map={demo.map}
                      set_downloading={props.set_downloading}
                      on_unauthorized={() => { }}
                    />

                    <ViewMapLinkComp
                      map={demo.map}
                      jwt={props.jwt}
                      on_unauthorized={() => { }}
                      set_downloading={(state) => {
                        props.set_downloading(state);
                      }}
                    />

                    <MapDownloadLinkComp
                      icon
                      hideText
                      map={demo.map}
                      jwt={props.jwt}
                      set_downloading={(state) => props.set_downloading(state)}
                      on_unauthorized={() => { }}
                    />
                  </div>

                  {props.showPlayer && <div className={classes.itemWrapper}>
                    <AuthorLink author={demo.uploader as User} steam_id={demo.uploader!.steamID} avatar />
                  </div>
                  }

                  <div className={classes.itemWrapper} style={{ display: "flex", gap: theme.spacing(2) }}>
                    <DemoTimeLinkComp
                      jwt={props.jwt}
                      demo={demo}
                      set_downloading={props.set_downloading}
                      on_unauthorized={() => { }}
                    />

                    <ViewDemoLink jwt={props.jwt} demo={demo} />

                    <AnalyzeLink
                      demo={demo}
                      user={props.user}
                    />

                    {demo.status === DemoWaitlistStatus.Beat &&
                      <Tooltip title={`${t("demo.table.beat")}`}>
                        <InfoIcon />
                      </Tooltip>
                    }
                  </div>

                  <div className={classes.itemWrapper}>
                    <Typography>
                      {wpnToString(demo.weapon)}
                    </Typography>
                  </div>

                  <div className={classes.itemWrapper}>
                    <Typography>
                      {formatDate(demo.uploadDate)}
                    </Typography>
                  </div>
                </div>
              }
            />
          </ListItem>
        )}
      </>
    }
  </List>;
};
