import { Chip, createStyles, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import MapDownloadLinkComp from "../demo/MapDownloadLinkComp";
import MapLinkComp from "../demo/MapLinkComp";
import ViewMapLinkComp from "../demo/page/ViewMapLinkComp";
import { MapInfo } from "../map/MapDataDTO";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as RWList } from 'react-window';
import { typesT, typeToColor, typeToTextColor } from "./page/Types";
import { difficultiesT, difficultyToColor, difficultyToTextColor } from "./page/Difficulties";

export interface UnfinishedMapsCompProps {
  maps: MapInfo[],
  jwt: string | null,
  set_loading: (state: boolean) => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsWrapper: {
      display: "grid",
      alignItems: "center",
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1)
    },
    itemLeftAlignWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      gap: theme.spacing(1)
    },
    unfinishedCountWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

enum SortMode {
  Name,
  Type,
  Difficulty
};

export default function UnfinishedMapsComp(props: UnfinishedMapsCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [sortMode, set_sortMode] = React.useState<SortMode>(SortMode.Name);
  const [sortDirection, set_sortDirection] = React.useState(1);
  const [sortedMaps, set_sortedMaps] = React.useState(props.maps);

  const [filterText, set_filterText] = React.useState("");

  const sorter = React.useCallback((a: MapInfo, b: MapInfo) => {
    if (sortMode === SortMode.Name) {
      return a.n.localeCompare(b.n) * sortDirection;
    }

    if (sortMode === SortMode.Type) {
      if (a.t && !b.t) {
        return -sortDirection;
      } else if (!a.t && b.t) {
        return sortDirection;
      } else if (a.t && b.t) {
        return a.t.localeCompare(b.t) * sortDirection;
      }
    }

    if (sortMode === SortMode.Difficulty) {
      if (a.d && !b.d) {
        return -sortDirection;
      } else if (!a.d && b.d) {
        return sortDirection;
      } else if (a.d && b.d) {
        return a.d.localeCompare(b.d) * sortDirection;
      }
    }

    return 0;
  }, [sortMode, sortDirection]);

  const getSortingIcon = React.useCallback((field: SortMode) => {
    if (field !== sortMode) {
      return null;
    }

    if (sortDirection === -1) {
      return <ArrowUpwardIcon />;
    }

    return <ArrowDownwardIcon />;
  }, [sortDirection, sortMode]);

  React.useEffect(() => {
    set_sortedMaps(props.maps
      .filter(mi => filterText.length === 0 ? true : mi.n.toLowerCase().includes(filterText.toLowerCase()))
      .sort(sorter));
  }, [sortDirection, sortMode, sorter, props.maps, filterText]);

  const RowHeight = 75;

  const Row = (input: { index: number, style: CSSProperties }) => {
    const data = sortedMaps[input.index];

    return <div
      className={classes.itemsWrapper}
      style={{
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        height: `${RowHeight}px`,
        ...input.style,
      }}
    >
      <div className={classes.itemWrapper}>
        <Typography>
          # {input.index + 1}
        </Typography>
      </div>

      <div className={classes.itemLeftAlignWrapper}>
        <MapLinkComp
          jwt={props.jwt}
          map={data.n}
          on_unauthorized={() => { }}
          set_downloading={(state) => props.set_loading(state)}
        />

        <ViewMapLinkComp
          map={data.n}
          jwt={props.jwt}
          on_unauthorized={() => { }}
          set_downloading={(state) => props.set_loading(state)}
        />

        <MapDownloadLinkComp
          icon
          hideText
          map={data.n}
          jwt={props.jwt}
          set_downloading={(state) => props.set_loading(state)}
          on_unauthorized={() => { }}
        />
      </div>

      <div className={classes.itemWrapper}>
        {data.t
          ? ((!data.t || data.t.length === 0) ? "n/a" : (data.t.split(',') as typesT[]).map(type =>
            <Chip
              key={`${data.n}_${type}`}
              label={type}
              style={{
                margin: 2,
                backgroundColor: typeToColor(type),
                color: typeToTextColor(type)
              }}
            />
          ))
          : <Typography variant="overline">n/a</Typography>
        }
      </div>

      <div className={classes.itemWrapper}>
        {data.d
          ? ((!data.d || data.d.length === 0) ? "n/a" : (data.d.split(',') as difficultiesT[]).map(difficulty =>
            <Chip
              key={`${data.n}_${difficulty}`}
              label={difficulty}
              style={{
                margin: 2,
                backgroundColor: difficultyToColor(difficulty),
                color: difficultyToTextColor(difficulty)
              }}
            />
          ))
          : <Typography variant="overline">n/a</Typography>
        }
      </div>
    </div>;
  };

  return <div
    style={{
      minHeight: 2000
    }}
  >
    <div className={classes.unfinishedCountWrapper}>
      {sortedMaps.length} {t(sortedMaps.length === 1 ? 'server_records.unfinished_count.singular' : 'server_records.unfinished_count.plural')}
    </div>

    <TextField
      value={filterText}
      onChange={(e) => {
        set_filterText(e.target.value.toLowerCase());
      }}
      autoComplete="off"
      variant="outlined"
      fullWidth
      label={t("demo.table.filter.map")}
      style={{
        paddingBottom: theme.spacing(6)
      }}
    />

    <div
      className={classes.itemsWrapper}
      style={{
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        paddingBottom: theme.spacing(6)
      }}
    >
      <div className={classes.itemWrapper}>
        <Typography>
          {t("server_records.no")}
        </Typography>
      </div>

      {[
        { mode: SortMode.Name, label: t("map.title") },
        { mode: SortMode.Type, label: t("map.type") },
        { mode: SortMode.Difficulty, label: t("map.difficulty") },
      ].map((data, index) =>
        <div
          key={index}
          className={classes.itemWrapper}
          onClick={() => {
            set_sortMode(data.mode);
            set_sortDirection(sortDirection * -1);
          }}
        >
          <Typography>
            {data.label}
          </Typography>

          {getSortingIcon(data.mode)}
        </div>
      )}
    </div>

    <AutoSizer>
      {(param: { width: number }) => (
        <RWList
          height={1800}
          width={param.width}
          itemSize={RowHeight}
          itemCount={sortedMaps.length}
        >
          {Row}
        </RWList>
      )}
    </AutoSizer>
  </div>;
};
