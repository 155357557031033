import React from "react";
import { createStyles, Link, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import { NEW_API_URL } from "../Config";
import CustomPaper from "../CustomPaper";

import ServerInfo from "./ServerInfo";
import MapLinkComp from "../demo/MapLinkComp";
import AuthorLink from "../user/AuthorLink";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";

import PlayersSparkLineComp from "./PlayersSparkLineComp";

export interface ServerStatusCompProps {
  jwt: string | null
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
  })
);

function ServerStatusComp(props: ServerStatusCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  interface ServersInfo {
    easy: ServerInfo | undefined | null,
    hard: ServerInfo | undefined | null,
    cups: ServerInfo | undefined | null,
  };

  const [receivedServerStatuses, set_receivedServerStatuses] = React.useState(false);

  const [serversInfo, set_serversInfo] = React.useState<ServersInfo>({
    easy: undefined,
    hard: undefined,
    cups: undefined
  });

  React.useEffect(() => {
    fetch(`${NEW_API_URL}/server/status`, {
      method: "GET",
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        const data = await response.json();

        if (!data) {
          return;
        }

        set_serversInfo(old => {
          return {
            ...old,
            easy: data.easy,
            hard: data.hard,
            cups: data.cups
          };
        });
      }
    }).catch((reason) => {
      console.error(reason);
    }).finally(() => set_receivedServerStatuses(true));
  }, [props]);

  const fakePlayersCount = (server: ServerInfo | undefined) => {
    if (!server) {
      return 0;
    }

    return server.bot_count;
  };

  const SmallAvatarThreshold = 10;

  const serverSkeleton = <Skeleton
    height="100px"
    width="100%"
    animation="wave"
    variant="rect"
  />;

  return <>
    {serversInfo.cups &&
      <CustomPaper>
        <div className={classes.wrapper}>
          <Tooltip title="Connect">
            <Link
              href="steam://connect/cups.romanian-jumpers.com"
              style={{
                display: "flex",
                color: "white"
              }}
            >
              <Typography variant="overline">
                Cup | Tournament server
              </Typography>
              <DirectionsRunIcon />
            </Link>
          </Tooltip>

          {serversInfo.cups &&
            <>
              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  paddingTop: theme.spacing(2),
                  alignItems: "baseline"
                }}
              >
                <Typography>
                  - {t('server_status.map')}
                </Typography>

                <MapLinkComp
                  map={serversInfo.cups.map}
                  jwt={props.jwt}
                  set_downloading={() => { }}
                  on_unauthorized={() => { }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "baseline"
                }}
              >
                <Typography>
                  - {t('server_status.players')} {serversInfo.cups.player_count - fakePlayersCount(serversInfo.cups)}/{serversInfo.cups.max_player_count}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "baseline"
                }}
              >
                <List dense>
                  {serversInfo.cups.players.map((player, index, players) =>
                    <ListItem key={index}>
                      <ListItemText>
                        <AuthorLink
                          author={player}
                          steam_id={player.steamID}
                          avatar
                          small={players.length >= SmallAvatarThreshold ? true : undefined}
                        />
                      </ListItemText>
                    </ListItem>)
                  }
                </List>
              </div>
            </>
          }
        </div>
      </CustomPaper>
    }

    <CustomPaper>
      {!receivedServerStatuses
        ? serverSkeleton
        : < div className={classes.wrapper}>
          <Tooltip title="Connect">
            <Link
              href="steam://connect/15.161.220.10"
              style={{
                display: "flex",
                color: "white"
              }}
            >
              <Typography variant="overline">
                Easy/Average server
              </Typography>
              <DirectionsRunIcon />
            </Link>
          </Tooltip>

          {serversInfo.easy &&
            <>
              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  paddingTop: theme.spacing(2),
                  alignItems: "baseline"
                }}
              >
                <Typography>
                  - {t('server_status.map')}
                </Typography>

                <MapLinkComp
                  map={serversInfo.easy.map}
                  jwt={props.jwt}
                  set_downloading={() => { }}
                  on_unauthorized={() => { }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                }}
              >
                <Typography>
                  - {t('server_status.players')} {serversInfo.easy.player_count - fakePlayersCount(serversInfo.easy)}/{serversInfo.easy.max_player_count}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <PlayersSparkLineComp serverInfo={serversInfo.easy} />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "baseline"
                }}
              >
                <List dense>
                  {serversInfo.easy.players.map((player, index, players) =>
                    <ListItem key={index}>
                      <ListItemText>
                        <AuthorLink
                          author={player}
                          steam_id={player.steamID}
                          avatar
                          small={players.length >= SmallAvatarThreshold ? true : undefined}
                        />
                      </ListItemText>
                    </ListItem>)
                  }
                </List>
              </div>
            </>
          }
        </div>
      }
    </CustomPaper >

    <CustomPaper>
      {!receivedServerStatuses
        ? serverSkeleton
        : <div className={classes.wrapper}>
          <Tooltip title="Connect">
            <Link
              href="steam://connect/18.102.1.137"
              style={{
                display: "flex",
                color: "white"
              }}
            >
              <Typography variant="overline">
                Hard/Extreme server
              </Typography>
              <DirectionsRunIcon />
            </Link>
          </Tooltip>

          {serversInfo.hard &&
            <>
              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  paddingTop: theme.spacing(2),
                  alignItems: "baseline"
                }}
              >
                <Typography>
                  - {t('server_status.map')}
                </Typography>

                <MapLinkComp
                  map={serversInfo.hard.map}
                  jwt={props.jwt}
                  set_downloading={() => { }}
                  on_unauthorized={() => { }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "baseline"
                }}
              >
                <Typography>
                  - {t('server_status.players')} {serversInfo.hard.player_count - fakePlayersCount(serversInfo.hard)}/{serversInfo.hard.max_player_count}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <PlayersSparkLineComp serverInfo={serversInfo.hard} />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "baseline"
                }}
              >
                <List dense>
                  {serversInfo.hard.players.map((player, index, players) =>
                    <ListItem key={index}>
                      <ListItemText>
                        <AuthorLink
                          author={player}
                          steam_id={player.steamID}
                          avatar
                          small={players.length >= SmallAvatarThreshold ? true : undefined}
                        />
                      </ListItemText>
                    </ListItem>)
                  }
                </List>
              </div>
            </>
          }
        </div>
      }
    </CustomPaper>
  </>;
};

export default React.memo(ServerStatusComp);
