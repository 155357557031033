import { createStyles, Link, makeStyles, Theme, Tooltip, useTheme } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../Config";

export interface ViewMapLinkCompProps {
  map: string,
  jwt: string | null,
  set_downloading: (state: boolean) => void,
  on_unauthorized: () => void,
  big?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: "grabbing",
      transition: "color 0.5s",
      transitionTimingFunction: "ease-in-out",
    },
  })
);

export default function ViewMapLinkComp(props: ViewMapLinkCompProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const theme = useTheme();

  const [hovering, set_hovering] = React.useState(false);

  return <Tooltip title={`${t("viewmap.title")}`}>
    <Link
      target="_blank"
      className={classes.link}
      style={{
        color: hovering ? theme.palette.error.dark : theme.palette.text.primary,
      }}
      href={`${API_URL}/viewDemo/3/${props.jwt}/${props.map}`}
      onClick={() => {
        if (! props.jwt) {
          props.on_unauthorized && props.on_unauthorized();
          return;
        }
      }}
      onMouseEnter={() => set_hovering(true)}
      onMouseLeave={() => set_hovering(false)}
    >
      <VisibilityIcon fontSize={props.big ? "large" : "small"}/>
    </Link>
  </Tooltip>;
};
