import { Backdrop, CircularProgress, createStyles, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import { challengeTypeToStr, challengeTypeToTranslationKey, playerPositionInTopToPoints } from "../challenge/Challenge";
import { ChallengeNewsItem } from "./NewsItem";
import RegularNewsItemComp from "./RegularNewsItemComp";
import ReactMarkdown from "react-markdown";
import NewsType from "./NewsType";
import AuthorLink from "../user/AuthorLink";
import { useTranslation } from "react-i18next";
import DemoWaitlistData from "../demo/DemoWaitlistData";
import React from "react";
import { API_URL } from "../Config";
import User from "../user/User";
import DemoTimeLinkComp from "../demo/DemoTimeLinkComp";
import ViewDemoLink from "../viewdemo/ViewDemoLink";
import MapLinkComp from "../demo/MapLinkComp";

export interface ChallengeItemCompProps {
  jwt: string | null,
  item: ChallengeNewsItem,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerImg: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      pointerEvents: "none",
      height: "auto",
      width: "95%",
      maxWidth: "60vw"
    },
    mdWrapper: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

export default function ChallengeItemComp(props: ChallengeItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const challengeInfoMD = "\t" + t("challenge.news.start.type", { type: challengeTypeToStr(props.item.challenge.type) })
    + "\n\t"
    + t(challengeTypeToTranslationKey(props.item.challenge.type))
    + "\n\t"
    + t("challenge.news.start.rules.general.map_time", { days: props.item.challenge.map_cycle_duration })
    + "\n\t"
    + t("challenge.news.start.rules.general.first_map", { map: props.item.challenge.map_cycle.split(',')[0] })
    + "\n\t"
    + t("challenge.news.start.rules.general.points");

  const [demos, set_demos] = React.useState<DemoWaitlistData[]>([]);
  const [loading, set_loading] = React.useState(false);

  React.useEffect(() => {
    fetch(`${API_URL}/challenges/${props.item.challenge_id}/demos`, {
      method: "GET",
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        set_demos(await response.json());
      }
    }).catch((reason) => {
      console.error(reason);
    });
  }, [props.item.challenge_id]);

  const splitContent = props.item.content.split(',');
  const previousMap = props.item.type === NewsType.ChallengeRoundEnd && splitContent.length === 2 ? splitContent[0] : "";
  const currentMap = props.item.type === NewsType.ChallengeRoundEnd && splitContent.length === 2 ? splitContent[1] : "";

  return <div>
    {props.item.type === NewsType.Challenge && <img src="/newChallenge.jpg" alt="romanian jumpers challenge banner" className={classes.bannerImg} />}
    {props.item.type === NewsType.ChallengeRoundEnd && props.item.content.length === 0 && <img src="/endChallenge.jpg" alt="romanian jumpers end challenge banner" className={classes.bannerImg} />}

    {props.item.type === NewsType.Challenge &&
      <ReactMarkdown
        className={classes.mdWrapper}
      >
        {challengeInfoMD}
      </ReactMarkdown>
    }

    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress size="5rem" color="secondary" />
    </Backdrop>

    {props.item.type === NewsType.Challenge && props.item.content.length !== 0 && <RegularNewsItemComp jwt={props.jwt} content={props.item.content} />}

    {props.item.type === NewsType.ChallengeRoundEnd && props.item.content.length !== 0 &&
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: theme.spacing(2),
          gap: theme.spacing(3),
          alignItems: "center"
        }}
      >
        <Typography variant="h5">
          {t("challenge.round_leaderboard")}
        </Typography>

        <List
          disablePadding
          style={{
            width: theme.spacing(100)
          }}
        >
          {demos.filter(d => d.map === previousMap)
            .sort((a, b) => a.time < b.time ? -1 : 1)
            .map((demo, demoIndex) =>
              <ListItem key={`${demo.map}_${demoIndex}`}>
                <ListItemText primary={
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      alignItems: "baseline",
                      gap: theme.spacing(4),
                      justifyItems: "stretch"
                    }}
                  >
                    <Tooltip title={`${t("challenge.points")}`}>
                      <Typography>
                        {playerPositionInTopToPoints(demoIndex)}
                      </Typography>
                    </Tooltip>

                    <AuthorLink
                      author={demo.uploader as User}
                      steam_id={demo.uploader!.steamID}
                      avatar
                      small
                    />

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: theme.spacing(2),
                        justifyItems: "stretch"
                      }}
                    >
                      <DemoTimeLinkComp
                        jwt={props.jwt}
                        demo={demo}
                        on_unauthorized={() => { }}
                        set_downloading={(state) => set_loading(state)}
                      />

                      <ViewDemoLink
                        demo={demo}
                        jwt={props.jwt}
                      />
                    </div>
                  </div>
                } />
              </ListItem>
            )}
        </List>

        <Typography>
          {t("challenge.map_changed")} <MapLinkComp
            jwt={props.jwt}
            map={currentMap}
            on_unauthorized={() => { }}
            set_downloading={(state) => set_loading(state)}
          />. {t("challenge.good_luck")}
        </Typography>
      </div>
    }

    {props.item.type === NewsType.ChallengeRoundEnd && props.item.content.length === 0 && props.item.challenge.winner &&
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: theme.spacing(2),
          gap: theme.spacing(3),
        }}
      >
        <Typography>
          {t("challenge.news.ended", { type: challengeTypeToStr(props.item.challenge.type) })}
        </Typography>

        <div
          style={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center"
          }}
        >
          <Typography>
            {t("challenge.news.congratulations.1")}
          </Typography>

          <AuthorLink avatar author={props.item.challenge.winner} steam_id={props.item.challenge.winner.steamID} />
          {props.item.challenge.winner2 && <AuthorLink avatar author={props.item.challenge.winner2} steam_id={props.item.challenge.winner2.steamID} />}
          {props.item.challenge.winner3 && <AuthorLink avatar author={props.item.challenge.winner3} steam_id={props.item.challenge.winner3.steamID} />}

          <Typography>
            {t("challenge.news.congratulations.2")}
          </Typography>
        </div>
      </div>
    }
  </div>;
};
