import { createStyles, Link, makeStyles, Theme, Tooltip, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../Config";

import GetAppIcon from '@material-ui/icons/GetApp';

import { saveAs } from "file-saver";

export interface MapDownloadLinkCompProps {
  map: string,
  jwt: string | null,
  set_downloading: (state: boolean) => void,
  on_unauthorized: () => void,
  useColor?: boolean,
  icon?: boolean,
  hideText?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: "grabbing",
      display: "flex",
      alignItems: "center"
    },
  })
);

export default function MapDownloadLinkComp(props: MapDownloadLinkCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {map, jwt, set_downloading, on_unauthorized} = props;
  const {t} = useTranslation();

  const onMapLinkClick = React.useCallback(() => {
    if (! jwt) {
      on_unauthorized && on_unauthorized();
      return;
    }

    set_downloading(true);

    let localMap = map;

    const routeStart = localMap.indexOf('[');

    if (routeStart !== -1) {
      localMap = localMap.substr(0, routeStart);
    }

    fetch(`${API_URL}/demo/map/${localMap}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const type = response.headers.get("content-type");

        // XJ still uses http and chrome (probably other browsers too) block the download
        if (type === "application/x-rar-compressed") {
          saveAs(await response.blob(), `${localMap}.rar`);
        } else {
          const mapUrl = await response.text();

          if (mapUrl.length !== 0) {
            const win = window.open(mapUrl, '_blank');

            if (win !== null) {
              win.focus();
            }
          }
        }
      }
    }).finally(() => {
      set_downloading(false);
    });
  }, [jwt, map, set_downloading, on_unauthorized]);

  return <Tooltip title={`${t("download_map", { map: map })}`}>
    <Link
      className={classes.link}
      onClick={() => onMapLinkClick()}
      style={{
        color: props.useColor ? theme.palette.secondary.dark : "white",
        fontWeight: props.useColor ? 600 : "normal"
      }}
    >
      {! props.hideText && map}
      {props.icon && <GetAppIcon fontSize="small"/>}
    </Link>
  </Tooltip>;
};
