import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";

export interface MapLinkCompProps {
  map: string,
  route?: string,
  jwt: string | null,
  set_downloading: (state: boolean) => void,
  on_unauthorized: () => void,
  useColor?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "white",
      cursor: "grabbing",
      textDecoration: "none"
    },
  })
);

export default function MapLinkComp(props: MapLinkCompProps) {
  const classes = useStyles();
  const theme = useTheme();

  return <Link
    to={`/mapdata/${props.map}`}
    className={classes.link}
    style={{
      color: props.useColor ? theme.palette.secondary.dark : "white",
      fontWeight: props.useColor ? 600 : "normal"
    }}
  >
    {props.map}{props.route && `[${props.route.replace("PLUS", "+")}]`}
  </Link>;
};
