import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  Fade,
  FormControlLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import BlockIcon from "@material-ui/icons/Block";

import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";

import DemoWaitlistStatus from "../DemoWaitlistStatus";
import DemoWaitlistData from "../DemoWaitlistData";
import { API_URL, NEW_API_URL } from "../../Config";
import { formatDate, formatMS, groupBy, isAdmin, isVIP } from "../../Util";

import UserType from "../../user/UserType";
import User from "../../user/User";

import CheckDemoStatus from "../CheckDemoStatus";
import ViewDemoLink from "../../viewdemo/ViewDemoLink";
import MapLinkComp from "../MapLinkComp";
import { useTranslation } from "react-i18next";
import Weapon, { wpnToString } from "../Weapon";
import AuthorLink from "../../user/AuthorLink";
import AnalyzeLink from "../../challenge/AnalyzeLink";
import ViewMapLinkComp from "./ViewMapLinkComp";

export interface DemosWaitlistProps {
  reloadWaitlistCount: () => void;
  user: User | undefined;
  jwt: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      width: "98%",
      padding: theme.spacing(2),
    },
    releaseWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      paddingTop: theme.spacing(4),
      justifyContent: "center",
      alignItems: "center",
    },
    analyzeAllWrapper: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fffffff",
    },
  })
);

const useWLItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusIcon: {
      paddingLeft: "10px",
      paddingRight: "20px",
    },
    actionButtonsWrapper: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(5),
    },
    rejectBtn: {
      marginLeft: "auto",
      marginRight: theme.spacing(1),
    },
    noUpperCase: {
      textTransform: "none",
    },
    confirmationWrapper: {
      margin: theme.spacing(3),
    },
    confirmationButtonWrapper: {
      paddingTop: theme.spacing(3),
      display: "flex",
    },
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    analysisLink: {
      color: theme.palette.info.dark,
      textDecoration: "none",
      cursor: "grabbing",
    },
    viewDemoLinkWrapper: {
      display: "flex",
      paddingLeft: theme.spacing(1),
      gap: theme.spacing(1),
    },
  })
);

interface DemoWaitlistItemProps {
  user: User | undefined;
  jwt: string | null;
  demoData: DemoWaitlistData;
  expandedName: string | undefined;
  set_expandedName: (name: string | undefined) => void;
  reload: () => void;
  status: CheckDemoStatus | undefined;
  isvip: boolean;
}

const stringToColor = (str: string) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
};

function DemoWaitlistItem(props: DemoWaitlistItemProps) {
  const classes = useWLItemStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    user,
    jwt,
    demoData,
    expandedName,
    set_expandedName,
    reload,
    status,
  } = props;

  const [rejectionMessage, set_rejectionMessage] = React.useState<string>(
    props.demoData.reason ? props.demoData.reason : ""
  );
  const [localStatus, set_localStatus] = React.useState(demoData.status);
  const [localWpn, set_localWpn] = React.useState(demoData.weapon);
  const [deleteConfirmationDialogOpen, set_deleteConfirmationDialogOpen] =
    React.useState(false);

  const onDownload = React.useCallback(() => {
    fetch(`${NEW_API_URL}/demos/kz/${demoData.demoMD5}/download`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const demoUrl = await response.text();

        saveAs(
          await (await fetch(demoUrl)).blob(),
          `${demoData.map}_${demoData.uploader!.displayName}_${formatMS(
            demoData.time,
            false
          )}.zip`
        );
      }
    });
  }, [demoData.demoMD5, demoData.map, demoData.time, demoData.uploader, jwt]);

  const handleChange = React.useCallback(
    (status: DemoWaitlistStatus) => {
      if (!props.user || !isAdmin(props.user)) {
        return;
      }

      fetch(`${NEW_API_URL}/waitlist/kz/${demoData.demoMD5}/status`, {
        method: "PATCH",
        body: JSON.stringify({
          status: status,
          reason: rejectionMessage,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          set_localStatus(status);

          if (status === DemoWaitlistStatus.Aproved) {
            set_rejectionMessage("");
          }

          set_expandedName(undefined);
          reload();
        }
      });
    },
    [
      jwt,
      props.user,
      set_expandedName,
      reload,
      rejectionMessage,
      demoData.demoMD5,
    ]
  );

  const onAproval = React.useCallback(() => {
    handleChange(DemoWaitlistStatus.Aproved);
  }, [handleChange]);

  const onReject = React.useCallback(() => {
    handleChange(DemoWaitlistStatus.Rejected);
  }, [handleChange]);

  const onDelete = React.useCallback(() => {
    if (!props.user || props.user.type !== UserType.Root) {
      return;
    }

    fetch(`${NEW_API_URL}/waitlist/kz?demoMD5=${demoData.demoMD5}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_expandedName(undefined);
        reload();
      }
    });
  }, [demoData.demoMD5, jwt, props.user, reload, set_expandedName]);

  const changeWeapon = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (!props.user || props.user.type !== UserType.Root) {
        return;
      }

      const newWeapon = event.target.value as Weapon;

      fetch(`${NEW_API_URL}/waitlist/kz/${demoData.demoMD5}/status`, {
        method: "PATCH",
        body: JSON.stringify({
          weapon: newWeapon,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          set_localWpn(newWeapon);

          reload();
        }
      });
    },
    [demoData.demoMD5, jwt, props.user, reload]
  );

  if (!props.user) {
    return null;
  }

  return (
    <Accordion
      style={{
        backgroundColor:
          localStatus === DemoWaitlistStatus.Rejected
            ? "rgb(100, 50, 50)"
            : theme.palette.background.default,
        marginTop: theme.spacing(3),
        width: "100%",
      }}
      square
      expanded={
        props.user && isAdmin(props.user) && demoData.demoMD5 === expandedName
      }
      onChange={() =>
        props.user &&
        isAdmin(props.user) &&
        set_expandedName(
          expandedName !== demoData.demoMD5 ? demoData.demoMD5 : undefined
        )
      }
    >
      <AccordionSummary
        expandIcon={
          props.user && isAdmin(props.user) ? <ExpandMoreIcon /> : null
        }
      >
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            localStatus === DemoWaitlistStatus.Aproved ? (
              <DoneIcon
                style={{ color: "rgb(0, 255, 0)" }}
                fontSize="large"
                className={classes.statusIcon}
              />
            ) : localStatus === DemoWaitlistStatus.Rejected ? (
              <BlockIcon
                style={{ color: "rgb(255, 0, 0)" }}
                fontSize="large"
                className={classes.statusIcon}
              />
            ) : (
              <HelpOutlineIcon
                fontSize="large"
                className={classes.statusIcon}
              />
            )
          }
          label={
            <>
              <div style={{ display: "flex", gap: theme.spacing(1) }}>
                <Typography>
                  <MapLinkComp
                    jwt={jwt}
                    map={demoData.map}
                    set_downloading={(_) => {}}
                    on_unauthorized={() => {}}
                  />

                  {` - `}

                  <Tooltip title="Download demo">
                    <Link
                      href="#"
                      onClick={(e) => {
                        onDownload();

                        e.preventDefault();
                      }}
                      color="textPrimary"
                    >
                      <b>{formatMS(demoData.time)}</b>
                    </Link>
                  </Tooltip>
                </Typography>

                {` - `}

                <Select
                  value={localWpn}
                  onChange={changeWeapon}
                  style={{
                    color: localWpn === Weapon.UNKNOWN ? "red" : "currentcolor",
                  }}
                  disabled={!props.user || props.user.type !== UserType.Root}
                >
                  {[
                    Weapon.UNKNOWN,
                    Weapon.USP_OR_KNIFE,
                    Weapon.AWP,
                    Weapon.SCOUT,
                    Weapon.FAMAS,
                    Weapon.M4A1,
                    Weapon.M249,
                  ].map((wpn, index) => (
                    <MenuItem key={index} value={wpn}>
                      {wpnToString(wpn)}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <Typography
                variant="overline"
                className={classes.noUpperCase}
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                {t("demo.uploaded_on")}
                {formatDate(demoData.uploadDate)}

                {props.user && isAdmin(props.user) && demoData.uploader && (
                  <>
                    {" "}
                    {t("demo.by")}{" "}
                    {
                      <AuthorLink
                        avatar
                        author={demoData.uploader}
                        steam_id={demoData.uploader.steamID}
                      />
                    }
                  </>
                )}

                {props.user &&
                  isAdmin(props.user) &&
                  demoData.prevRecord &&
                  demoData.prevRecord.uploader && (
                    <>
                      {" "}
                      {t("demo.current")}{" "}
                      {
                        <AuthorLink
                          avatar
                          author={demoData.prevRecord.uploader}
                          steam_id={demoData.prevRecord.uploader.steamID}
                        />
                      }
                    </>
                  )}
              </Typography>

              <div className={classes.viewDemoLinkWrapper}>
                <ViewMapLinkComp
                  map={demoData.map}
                  jwt={props.jwt}
                  on_unauthorized={() => {}}
                  set_downloading={() => {}}
                />

                {(isAdmin(props.user) ||
                  (props.isvip &&
                    props.user!.steamID === props.demoData.uploader!.steamID) ||
                  props.user!.steamID === props.demoData.uploader!.steamID) && (
                  <ViewDemoLink
                    jwt={jwt}
                    demo={demoData}
                    on_click={() => {
                      props.set_expandedName(props.demoData.demoMD5);
                    }}
                  />
                )}
              </div>

              {props.user && isAdmin(props.user) && (
                <Typography
                  variant="button"
                  style={{
                    paddingLeft: theme.spacing(1),
                    color:
                      status?.probablyCheated === true
                        ? "red"
                        : status?.success === false
                        ? "red"
                        : "green",
                  }}
                >
                  {status !== undefined
                    ? status.success
                      ? status.probablyCheated
                        ? t("demo.analysis.probably_cheat", {
                            cheat: status.cheatType,
                          })
                        : t("demo.analysis.probably_clean")
                      : t("demo.analysis.failed")
                    : null}
                </Typography>
              )}
            </>
          }
        />
      </AccordionSummary>

      {props.user && isAdmin(props.user) && (
        <AccordionDetails>
          <div className={classes.actionButtonsWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={localStatus === DemoWaitlistStatus.Aproved}
              onClick={onAproval}
            >
              {t("demo.aprove")}
            </Button>

            <AnalyzeLink demo={demoData} user={props.user} button />

            {user && user.type === UserType.Root && (
              <>
                <Button
                  className={classes.rejectBtn}
                  variant="outlined"
                  color="secondary"
                  onClick={() => set_deleteConfirmationDialogOpen(true)}
                >
                  {t("demo.delete.button")}
                </Button>

                <Dialog
                  onClose={() => set_deleteConfirmationDialogOpen(false)}
                  open={deleteConfirmationDialogOpen}
                >
                  <div className={classes.confirmationWrapper}>
                    <Typography>
                      {t("demo.delete.confirmation_msg")}{" "}
                      <i>'{demoData.demoFileName}'</i> ?
                    </Typography>

                    <div className={classes.confirmationButtonWrapper}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onDelete}
                      >
                        <Typography>{t("demo.delete.yes")}</Typography>
                      </Button>

                      <Button
                        variant="outlined"
                        style={{ marginLeft: "auto" }}
                        onClick={() => set_deleteConfirmationDialogOpen(false)}
                      >
                        <Typography>{t("demo.delete.no")}</Typography>
                      </Button>
                    </div>
                  </div>
                </Dialog>
              </>
            )}

            <Button
              className={classes.rejectBtn}
              variant="contained"
              color="secondary"
              disabled={
                localStatus === DemoWaitlistStatus.Rejected ||
                rejectionMessage.length === 0
              }
              onClick={onReject}
            >
              {t("demo.reject")}
            </Button>
          </div>

          <TextField
            label="Motiv"
            variant="outlined"
            fullWidth
            value={rejectionMessage}
            onChange={(e) => set_rejectionMessage(e.target.value)}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default function DemosWaitlist(props: DemosWaitlistProps) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();

  const [expanded, set_expanded] = React.useState<string>();
  const [waitlistData, set_waitlistData] = React.useState<DemoWaitlistData[]>(
    []
  );
  const [releaseText, set_releaseText] = React.useState("");

  const [checkDemosStatuses, set_checkDemosStatuses] = React.useState<
    CheckDemoStatus[]
  >([]);
  const [checkingDemos, set_checkingDemos] = React.useState(false);
  const [loading, set_loading] = React.useState(true);

  const [vip, set_vip] = React.useState(false);

  const reloadWaitList = React.useCallback(() => {
    fetch(`${NEW_API_URL}/waitlist/kz/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          const data = (await response.json()) as DemoWaitlistData[];

          set_waitlistData(data);
        }
      })
      .finally(() => {
        props.reloadWaitlistCount();
        set_loading(false);
      });
  }, [props]);

  const onMakeRelease = React.useCallback(() => {
    if (!props.user || !isAdmin(props.user)) {
      return;
    }

    fetch(`${NEW_API_URL}/news/kz/release`, {
      method: "POST",
      body: JSON.stringify({
        demos: waitlistData,
        description: releaseText,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.jwt}`,
      },
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        history.push("/");
      }
    });
  }, [history, props.jwt, props.user, releaseText, waitlistData]);

  const onAnalyzeAll = React.useCallback(() => {
    if (!props.user || !isAdmin(props.user)) {
      return;
    }

    set_checkingDemos(true);

    fetch(`${API_URL}/demo/checkMultiple`, {
      method: "POST",
      body: JSON.stringify({
        demos: waitlistData
          .filter((d) => d.status === DemoWaitlistStatus.None)
          .map((d) => d.demoMD5),
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
        } else {
          const data = await response.json();

          console.log(data);

          set_checkDemosStatuses(data);
        }
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => {
        set_checkingDemos(false);
      });
  }, [props.jwt, props.user, waitlistData]);

  // const onReparseAll = React.useCallback(() => {
  //   if (!props.user || !isAdmin(props.user)) {
  //     return;
  //   }

  //   set_checkingDemos(true);

  //   fetch(`${API_URL}/demo/reparse`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       demos: waitlistData.map(d => d.demoMD5)
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${props.jwt}`
  //     }
  //   }).then(async (response) => {
  //     if (response.status !== 200) {
  //     } else {
  //       // const data = await response.json();
  //     }
  //   }).catch((reason) => {
  //     console.error(reason);
  //   }).finally(() => {
  //     set_checkingDemos(false);
  //   });
  // }, [props.jwt, props.user, waitlistData]);

  React.useEffect(() => {
    reloadWaitList();

    if (props.user) {
      isVIP(props.user, props.jwt).then((value) => set_vip(value));
    }
  }, [reloadWaitList, props.jwt, props.user]);

  const downloadAll = React.useCallback(() => {
    set_loading(true);

    fetch(`${API_URL}/release/demos`, {
      method: "POST",
      body: JSON.stringify({
        demos: waitlistData.map((d) => d.demoMD5),
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          saveAs(await response.blob(), `release_demos.zip`);
        }
      })
      .finally(() => {
        set_loading(false);
      });
  }, [waitlistData, props.jwt]);

  if (!props.jwt || !props.user) {
    return <div className={classes.wrapperDiv}>Neaturizat</div>;
  }

  const allDemosChecked = () => {
    return (
      waitlistData.length !== 0 &&
      waitlistData.findIndex(
        (demoWlData: DemoWaitlistData) =>
          demoWlData.status === DemoWaitlistStatus.None
      ) === -1
    );
  };

  const allApprovedDemos = waitlistData.filter(
    (d) => d.status === DemoWaitlistStatus.Aproved
  );
  const allNotSetDemos = waitlistData.filter(
    (d) => d.status === DemoWaitlistStatus.None
  );

  const duplicateAcceptedDemos = Array.from(
    groupBy(
      allApprovedDemos,
      (d) => `${d.map} - ${wpnToString(d.weapon)}`
    ).entries()
  ).filter(([_, demos]) => demos.length > 1);

  const demosWithoutKnownWeapon = allApprovedDemos.filter(
    (d) => d.weapon === Weapon.UNKNOWN
  );

  const demosGroupedByPlayer = Array.from(
    groupBy(
      allNotSetDemos.concat(allApprovedDemos),
      (demo: DemoWaitlistData) => demo.uploader!.displayName
    ).entries()
  ).sort((a, b) => a[0].localeCompare(b[0]));

  const demosSorter = (a: DemoWaitlistData, b: DemoWaitlistData) => {
    // if (a.status === DemoWaitlistStatus.None && b.status !== DemoWaitlistStatus.None) {
    //   return -1;
    // } else if (a.status === DemoWaitlistStatus.Aproved && b.status === DemoWaitlistStatus.None) {
    //   return 1;
    // } else if (a.status === b.status) {
    //   if (a.map === b.map) {
    //     return a.uploadDate < b.uploadDate ? -1 : 1;
    //   } else {
    if (a.map === b.map) {
      return a.time < b.time ? -1 : 1;
    } else {
      return a.map.localeCompare(b.map);
    }
    //   }
    // }

    // return -1;
  };

  return (
    <div className={classes.wrapperDiv}>
      <Tooltip title={`${t("demo.waiting.tooltip")}`}>
        <Typography variant="overline">
          {t("demo.waiting.content", { total: waitlistData.length })}
        </Typography>
      </Tooltip>

      {!isAdmin(props.user) && (
        <>
          <Tooltip title={`${t("demo.my_waiting.tooltip")}`}>
            <Typography variant="overline">
              {t("demo.my_waiting.content", { total: waitlistData.length })}
            </Typography>
          </Tooltip>
        </>
      )}

      {isAdmin(props.user) && (
        <Typography variant="overline">
          {t("demo.accepted")}{" "}
          {
            waitlistData.filter((a) => a.status === DemoWaitlistStatus.Aproved)
              .length
          }
        </Typography>
      )}

      {waitlistData.length === 0 && (
        <Fade in timeout={500} unmountOnExit>
          <Typography>
            <br />0 demos :(
          </Typography>
        </Fade>
      )}

      {isAdmin(props.user) &&
        demosGroupedByPlayer.length !== 0 &&
        demosGroupedByPlayer.map(([player, data], index) => {
          return (
            <Fade in timeout={500 + 100 * index} unmountOnExit key={index}>
              <Typography variant="body2">
                {player} - {data.length} demo(s)
              </Typography>
            </Fade>
          );
        })}

      {isAdmin(props.user) && waitlistData.length !== 0 && (
        <div>
          <Button variant="contained" color="primary" onClick={downloadAll}>
            Download All
          </Button>
        </div>
      )}

      {waitlistData.sort(demosSorter).map((demo) => (
        <Fade in timeout={500} unmountOnExit key={demo.demoFileName}>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: alpha(
                  `${stringToColor(demo.map + wpnToString(demo.weapon))}`,
                  0.8
                ),
                display: "inline-block",
                minHeight: "1vh",
                minWidth: theme.spacing(2),
                marginTop: theme.spacing(2.9),
                borderTopLeftRadius: theme.spacing(2),
                borderBottomLeftRadius: theme.spacing(2),
              }}
            />

            <DemoWaitlistItem
              jwt={props.jwt}
              user={props.user}
              demoData={demo}
              expandedName={expanded}
              set_expandedName={set_expanded}
              reload={reloadWaitList}
              status={checkDemosStatuses.find(
                (s) => s.demoMD5 === demo.demoMD5
              )}
              isvip={vip}
            />
          </div>
        </Fade>
      ))}

      <Backdrop className={classes.backdrop} open={checkingDemos || loading}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>

      {isAdmin(props.user) &&
        waitlistData.length !== 0 &&
        !allDemosChecked() && (
          <Fade in timeout={500} unmountOnExit>
            <div className={classes.analyzeAllWrapper}>
              <Tooltip title={`${t("demo.analyze.tooltip")}`}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onAnalyzeAll}
                >
                  {t("demo.analyze.all")}
                </Button>
              </Tooltip>
            </div>
          </Fade>
        )}

      {/* {isAdmin(props.user) && waitlistData.length !== 0 &&
      <Fade in timeout={500} unmountOnExit>
        <div className={classes.analyzeAllWrapper}>
          <Tooltip title={`Reparse all demos for`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onReparseAll}
            >
              Reparse time info from all demos
            </Button>
          </Tooltip>
        </div>
      </Fade>
    } */}

      {isAdmin(props.user) &&
      allDemosChecked() &&
      duplicateAcceptedDemos.length === 0 &&
      demosWithoutKnownWeapon.length === 0 ? (
        <Fade in timeout={500} unmountOnExit>
          <div className={classes.releaseWrapper}>
            <TextField
              label={`${t("demo.release.label")}`}
              fullWidth
              multiline
              minRows={5}
              variant="outlined"
              value={releaseText}
              onChange={(e) => set_releaseText(e.target.value)}
            />

            <Button
              color="primary"
              variant="contained"
              disabled={releaseText.length === 0}
              onClick={onMakeRelease}
            >
              Release !
            </Button>
          </div>
        </Fade>
      ) : (
        <div>
          {duplicateAcceptedDemos.map(([map, demos]) => (
            <Typography style={{ color: "red" }}>
              {t("demo.release.same_map_weapon_error", {
                count: demos.length,
                map: demos[0].map,
                weapon: wpnToString(demos[0].weapon),
              })}
            </Typography>
          ))}

          {demosWithoutKnownWeapon.map((d) => (
            <Typography style={{ color: "red" }}>
              {t("demo.release.unknown_weapon_error", {
                map: d.map,
                player: d.uploader?.displayName,
              })}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
}
