import { createStyles, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import MapLinkComp from "../demo/MapLinkComp";
import AuthorLink from "../user/AuthorLink";
import { formatDate, formatMS } from "../Util";
import ServerRecord from "./ServerRecord";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ViewMapLinkComp from "../demo/page/ViewMapLinkComp";
import MapDownloadLinkComp from "../demo/MapDownloadLinkComp";

import { FixedSizeList as RWList } from 'react-window';

export enum SortMode {
  Time,
  Place,
  Date,
  Map,
  Unknown
};

export interface ServerRecordListCompProps {
  jwt: string | null,
  records: ServerRecord[],
  isNoob?: boolean,
  isAa100: boolean,
  useCustomIndex?: boolean,
  defaultSortMode?: SortMode,
  set_loading: (state: boolean) => void,
  disableSorting?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsWrapper: {
      display: "grid",
      // alignItems: "center",
      // justifyItems: "center",
      height: theme.spacing(5),
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
    itemWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1)
    },
    itemLeftAlignWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      gap: theme.spacing(1)
    },
  })
);

export default function ServerRecordListComp(props: ServerRecordListCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const top1count = React.useMemo(() => props.records.filter(r => r.custom_index === 1).length, [props.records]);
  const top2count = React.useMemo(() => props.records.filter(r => r.custom_index === 2).length, [props.records]);
  const top3count = React.useMemo(() => props.records.filter(r => r.custom_index === 3).length, [props.records]);

  const [sortMode, set_sortMode] = React.useState<SortMode>(props.defaultSortMode ? props.defaultSortMode : SortMode.Time);
  const [sortDirection, set_sortDirection] = React.useState(1);
  const [sortedRecords, set_sortedRecords] = React.useState(props.records);

  const [filterText, set_filterText] = React.useState("");

  const sorter = React.useCallback((a: ServerRecord, b: ServerRecord) => {
    if (sortMode === SortMode.Time || (sortMode === SortMode.Place && !a.custom_index)) {
      return (a.time < b.time ? -1 : 1) * sortDirection;
    }

    if (sortMode === SortMode.Map) {
      if (props.useCustomIndex) {
        return a.map.localeCompare(b.map) * sortDirection;
      } else {
        const aPlayerName = a.player?.displayName || a.player_name;
        const bPlayerName = b.player?.displayName || b.player_name;

        return aPlayerName.localeCompare(bPlayerName) * sortDirection;
      }
    }

    if (sortMode === SortMode.Date) {
      return (a.run_date < b.run_date ? -1 : 1) * sortDirection;
    }

    if (a.custom_index && b.custom_index) {
      return (a.custom_index < b.custom_index ? -1 : 1) * sortDirection;
    }

    return 0;
  }, [sortMode, sortDirection, props.useCustomIndex]);

  const getSortingIcon = React.useCallback((field: SortMode) => {
    if (field !== sortMode || props.disableSorting) {
      return null;
    }

    if (sortDirection === -1) {
      return <ArrowUpwardIcon />;
    }

    return <ArrowDownwardIcon />;
  }, [sortDirection, sortMode, props.disableSorting]);

  React.useEffect(() => {
    set_sortedRecords(old => {
      const newRecs = old.filter(r => filterText.length === 0 ? true : r.map.toLowerCase().includes(filterText.toLowerCase()));

      if (!props.disableSorting) {
        newRecs.sort(sorter);
      }

      return newRecs;
    });
  }, [sortDirection, sortMode, props.records, props.disableSorting, sorter, filterText]);

  const cameraToStr = (camera: number) => {
    switch (camera) {
      case 1: return "3rd person";
      case 2: return "top down";

      default: return null;
    }
  };

  const RowHeight = 75;

  const Row = (input: { index: number, style: CSSProperties }) => {
    const rec = sortedRecords[input.index];

    return <div
      className={classes.itemsWrapper}
      style={{
        gridTemplateColumns: props.isNoob ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr",
        height: `${RowHeight}px`,
        ...input.style,
      }}
    >
      <div className={classes.itemWrapper}>
        <Typography>
          # {props.useCustomIndex ? rec.custom_index : input.index + 1}
        </Typography>
      </div>

      {props.useCustomIndex
        ?
        <div
          className={classes.itemLeftAlignWrapper}
          style={{
            alignItems: "center"
          }}
        >
          <MapLinkComp
            map={rec.map}
            jwt={props.jwt}
            set_downloading={props.set_loading}
            on_unauthorized={() => { }}
          />

          <ViewMapLinkComp
            map={rec.map}
            jwt={props.jwt}
            on_unauthorized={() => { }}
            set_downloading={props.set_loading}
          />

          <MapDownloadLinkComp
            icon
            hideText
            map={rec.map}
            jwt={props.jwt}
            set_downloading={props.set_loading}
            on_unauthorized={() => { }}
          />
        </div>
        :
        <div
          style={{
            display: "flex",
            width: "100%"
          }}
        >
          {(rec.player && rec.player.steamID)
            ?
            <AuthorLink author={rec.player} steam_id={rec.player.steamID} avatar />
            :
            (rec.sid !== "STEAM_ID_LAN" && rec.sid !== "BOT" && !rec.sid.startsWith("VALVE")) ?
              <AuthorLink author={rec.player_name} steam_id={rec.sid} avatar />
              :
              <Typography>
                {rec.player_name}
              </Typography>
          }

          <Typography variant="overline">
            {cameraToStr(rec.camera)}
          </Typography>
        </div>
      }

      <div className={classes.itemWrapper}>
        <Typography>
          {formatMS(rec.time)}
        </Typography>
      </div>

      {props.isNoob &&
        <div className={classes.itemWrapper}>
          <Typography>
            {rec.cps} / {rec.gcs}
          </Typography>
        </div>
      }

      <div className={classes.itemWrapper}>
        <Typography>
          {formatDate(rec.run_date, true)}
        </Typography>
      </div>
    </div>;
  };

  if (props.records.length === 0) {
    return null;
  }

  return <div
    style={{
      minHeight: 2000
    }}
  >
    {props.useCustomIndex && <>
      <div className={classes.itemWrapper}>
        {props.records.length} {t(props.records.length === 1 ? 'server_records.count.singular' : 'server_records.count.plural')}
      </div>

      <div className={classes.itemWrapper}>
        <Typography>
          #1 {t('server_records.on')} {top1count} {t('server_records.maps')} | #2 {t('server_records.on')} {top2count} {t('server_records.maps')} | #3 {t('server_records.on')} {top3count} {t('server_records.maps')}
        </Typography>
      </div>
    </>}

    {props.useCustomIndex && <TextField
      value={filterText}
      onChange={(e) => {
        set_filterText(e.target.value.toLowerCase());
      }}
      autoComplete="off"
      variant="outlined"
      fullWidth
      label={t("demo.table.filter.map")}
      style={{
        paddingBottom: theme.spacing(2)
      }}
    />}

    <div
      className={classes.itemsWrapper}
      style={{
        gridTemplateColumns: props.isNoob ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr",
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(2)
      }}
    >
      {[
        { mode: SortMode.Place, label: t("server_records.no"), include: true, sortable: props.useCustomIndex },
        { mode: SortMode.Map, label: t(props.useCustomIndex ? "server_records.map" : "server_records.player"), include: true, sortable: true },
        { mode: SortMode.Time, label: t("server_records.time"), include: true, sortable: true },
        { mode: SortMode.Unknown, label: "CP/GC", include: props.isNoob, sortable: true },
        { mode: SortMode.Date, label: t("server_records.date"), include: true, sortable: true },
      ]
        .filter(d => d.include)
        .map((data, index) =>
          <div
            key={index}
            className={classes.itemWrapper}
            onClick={data.mode === SortMode.Unknown ? undefined : () => {
              if (data.sortable) {
                set_sortMode(data.mode);
                set_sortDirection(sortDirection * -1);
              }
            }}
          >
            <Typography>
              {data.label}
            </Typography>

            {data.mode !== SortMode.Unknown && getSortingIcon(data.mode)}
          </div>
        )}
    </div>

    <RWList
      height={1800}
      width={'100%'}
      itemSize={RowHeight}
      itemCount={sortedRecords.length}
    >
      {Row}
    </RWList>
  </div>;
};
