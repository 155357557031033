import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, createStyles, Dialog, Fade, FormControlLabel, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../Config";
import MapStatus from "../../map/MapStatus";
import MapWaitlistData from "../../map/MapWaitlistData";
import User from "../../user/User";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BlockIcon from '@material-ui/icons/Block';
import AuthorLink from "../../user/AuthorLink";
import ViewMapLinkComp from "./ViewMapLinkComp";
import MapLinkComp from "../MapLinkComp";
import { useHistory } from "react-router-dom";
import UserType from "../../user/UserType";
import ServerAdminMapTypeComp from "../../server/page/ServerAdminMapTypeComp";
import ServerAdminMapDifficultyComp from "../../server/page/ServerAdminMapDifficultyComp";

import { difficulties, difficultiesT } from "../../server/page/Difficulties";
import { types, typesT } from "../../server/page/Types";
import MapDownloadLinkComp from "../MapDownloadLinkComp";

interface MapItemProps {
  jwt: string | null,
  item: MapWaitlistData,
  expandedName: string | undefined
  set_expandedName: (value: string | undefined) => void,
  set_downloading: (value: boolean) => void,
  user: User,
  reload: () => void,
};

const useItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusIcon: {
      paddingLeft: "10px",
      paddingRight: "20px"
    },
    actionButtonsWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "row"
    },
    rejectBtn: {
      marginLeft: "auto",
      marginRight: theme.spacing(1)
    },
    confirmationWrapper: {
      margin: theme.spacing(3)
    },
    confirmationButtonWrapper: {
      paddingTop: theme.spacing(3),
      display: "flex"
    },
  })
);

function MapItem(props: MapItemProps) {
  const classes = useItemStyles();
  const theme = useTheme();
  const {t} = useTranslation();

  const [localStatus, set_localStatus] = React.useState(props.item.status);
  const [rejectionMessage, set_rejectionMessage] = React.useState("");
  const [mapTypes, set_mapTypes] = React.useState<number[]>(props.item.types ? props.item.types.split(',').map(name => types.indexOf(name as typesT)) : []);
  const [mapDifficulties, set_mapDifficulties] = React.useState<number[]>(props.item.difficulties ? props.item.difficulties.split(',').map(diff => difficulties.indexOf(diff as difficultiesT)) : []);
  
  const [deleteConfirmationDialogOpen, set_deleteConfirmationDialogOpen] = React.useState(false);

  const {jwt, item, set_expandedName, reload} = props;

  const handleChange = React.useCallback((status: MapStatus, close = true, inTypes: number[] | null = null, inDifficulties: number[] | null = null) => {
    fetch(`${API_URL}/maps/${item.name}`, {
      method: "PATCH",
      body: JSON.stringify({
        status: status,
        reason: rejectionMessage,
        types: (inTypes ? inTypes : mapTypes).map(value => types[value]).join(','),
        difficulties: (inDifficulties ? inDifficulties : mapDifficulties).map(value => difficulties[value]).join(',')
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_localStatus(status);

      if (status === MapStatus.Accepted) {
          set_rejectionMessage("");
        }

        if (close) {
          set_expandedName(undefined);
        }

        reload();
      }
    }).catch((e) => {
      console.error(e);
    });
  }, [jwt, item.name, rejectionMessage, set_expandedName, reload, mapDifficulties, mapTypes]);

  const onAproval = () => {
    handleChange(MapStatus.Accepted);
  };

  const onReject = () => {
    handleChange(MapStatus.Rejected);
  };

  const onDelete = React.useCallback(() => {
    if (props.user.type !== UserType.Root) {
      return;
    }

    fetch(`${API_URL}/maps/${props.item.name}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_expandedName(undefined);
        reload();
      }
    });
  }, [jwt, props.item.name, props.user.type, reload, set_expandedName]);

  return <Accordion
    style={{
      backgroundColor: localStatus === MapStatus.Rejected ? "rgb(100, 50, 50)" : theme.palette.background.default,
      marginTop: theme.spacing(3)
    }}
    square
    expanded={props.item.name === props.expandedName}
    onChange={() => props.set_expandedName(props.expandedName !== props.item.name ? props.item.name : undefined)}
>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
    >
      <FormControlLabel
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          localStatus === MapStatus.Accepted ?
            <DoneIcon style={{color: "rgb(0, 255, 0)"}} fontSize="large" className={classes.statusIcon}/> :
          localStatus === MapStatus.Rejected ?
            <BlockIcon style={{color: "rgb(255, 0, 0)"}} fontSize="large" className={classes.statusIcon}/> :
            <HelpOutlineIcon fontSize="large" className={classes.statusIcon}/>
        }
        label={
          <div
            style={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center"
            }}
          >
            <MapLinkComp
              map={props.item.name}
              jwt={props.jwt}
              set_downloading={props.set_downloading}
              on_unauthorized={() => {}}
            />

            <ViewMapLinkComp
              map={props.item.name}
              jwt={props.jwt}
              set_downloading={props.set_downloading}
              on_unauthorized={() => {}}
            />

            <MapDownloadLinkComp
              hideText
              icon
              map={props.item.name}
              jwt={props.jwt}
              set_downloading={props.set_downloading}
              on_unauthorized={() => { }}
            />

            <Typography>de</Typography>

            <AuthorLink author={props.item.author} steam_id={props.item.author.steamID} avatar/>
          </div>
        }
      />
    </AccordionSummary>

    <AccordionDetails>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <div
          style={{
            width: "100%",
            paddingBottom: theme.spacing(6)
          }}
        >
          <ServerAdminMapTypeComp
            set_types={(types) => {
              set_mapTypes(types);

              handleChange(props.item.status, false, types, null);
            }}
            types={mapTypes}
          />

          <ServerAdminMapDifficultyComp
            difficulties={mapDifficulties}
            set_difficulties={(difficulties) => {
              set_mapDifficulties(difficulties);

              handleChange(props.item.status, false, null, difficulties);
            }}
          />
        </div>

        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={localStatus === MapStatus.Accepted}
            onClick={onAproval}
          >
            Accept
          </Button>

          {props.user.type === UserType.Root && <>
              <Button
                className={classes.rejectBtn}
                variant="outlined"
                color="secondary"
                onClick={() => set_deleteConfirmationDialogOpen(true)}
              >
                {t("maps.delete.button")}
              </Button>

              <Dialog
                onClose={() => set_deleteConfirmationDialogOpen(false)}
                open={deleteConfirmationDialogOpen}
              >
                <div className={classes.confirmationWrapper}>
                  <Typography>
                    {t("maps.delete.confirmation_msg")} <i>'{props.item.name}'</i> ?
                  </Typography>

                  <div className={classes.confirmationButtonWrapper}>
                    <Button variant="contained" color="secondary" onClick={onDelete}>
                      <Typography>
                        {t("maps.delete.yes")}
                      </Typography>
                    </Button>

                    <Button variant="outlined" style={{ marginLeft: "auto" }} onClick={() => set_deleteConfirmationDialogOpen(false)}>
                      <Typography>
                        {t("maps.delete.no")}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Dialog>
            </>
          }

          <Button
            className={classes.rejectBtn}
            variant="contained"
            color="secondary"
            disabled={localStatus === MapStatus.Rejected || rejectionMessage.length === 0}
            onClick={onReject}
          >
            Reject
          </Button>

          <TextField
            label="Motiv"
            variant="outlined"
            value={rejectionMessage}
            onChange={(e) => set_rejectionMessage(e.target.value)}
          />
        </div>
      </div>
    </AccordionDetails>
  </Accordion>;
}

export interface MapsWaitlistProps {
  jwt: string | null,
  user: User | undefined,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    releaseWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      paddingTop: theme.spacing(4),
      justifyContent: "center",
      alignItems: "center"
    },
  })
);

export default function MapsWaitlist(props: MapsWaitlistProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();

  const [maps, set_maps] = React.useState<MapWaitlistData[]>([]);
  const [expandedName, set_expandedName] = React.useState<string | undefined>(undefined);

  const [releaseText, set_releaseText] = React.useState("");

  const [loading, set_loading] = React.useState(true);

  const reloadWaitList = React.useCallback(() => {
    fetch(`${API_URL}/maps`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_maps(await response.json());
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt]);

  React.useEffect(() => {
    reloadWaitList();
  }, [reloadWaitList]);

  const allMapsChecked = React.useCallback(() =>
    maps.length !== 0 && maps.map(m => m.status !== MapStatus.None).indexOf(false) === -1
  , [maps]);

  const onMakeRelease = React.useCallback(() => {
    fetch(`${API_URL}/maps/release`, {
      method: "POST",
      body: JSON.stringify({
        description: releaseText
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        history.push('/');
      }
    });
  }, [props.jwt, history, releaseText]);

  if (! props.user) {
    return <div className={classes.wrapperDiv}>
      {t("unauthorized")}
    </div>;
  }

  return <div className={classes.wrapperDiv}>
    <Typography variant="overline">
      {t("maps.label")}
    </Typography>

    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress size="5rem" color="secondary"/>
    </Backdrop>

    {maps.length === 0 &&
      <Typography>
        0 maps :(
      </Typography>
    }

    {maps.sort((a,b) => a.name.localeCompare(b.name))
      .map(map =>
        <MapItem
          key={map.name}
          jwt={props.jwt}
          user={props.user as User}
          item={map}
          expandedName={expandedName}
          set_expandedName={set_expandedName}
          set_downloading={set_loading}
          reload={reloadWaitList}
        />)
      }

    {allMapsChecked() &&
      <Fade in timeout={500} unmountOnExit>
        <div className={classes.releaseWrapper}>
          <TextField
            label={`${t("maps.release.label")}`}
            fullWidth
            multiline
            minRows={5}
            variant="outlined"
            value={releaseText}
            onChange={(e) => set_releaseText(e.target.value)}
          />

          <Button color="primary" variant="contained" disabled={releaseText.length === 0} onClick={onMakeRelease}>
            Release !
          </Button>
        </div>
      </Fade>
    }
  </div>;
};
