import { Accordion, AccordionDetails, AccordionSummary, Backdrop, CircularProgress, createStyles, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import Challenge, { challengeTypeToStr, challengeTypeToTranslationKey, playerPositionInTopToPoints } from "./Challenge";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

import { useTranslation } from "react-i18next";
import moment from "moment";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import React from "react";
import { API_URL } from "../Config";
import DemoTimeLinkComp from "../demo/DemoTimeLinkComp";
import AuthorLink from "../user/AuthorLink";
import ViewDemoLink from "../viewdemo/ViewDemoLink";
import MapLinkComp from "../demo/MapLinkComp";
import DemoWaitlistData from "../demo/DemoWaitlistData";
import User from "../user/User";
import AnalyzeLink from "./AnalyzeLink";

export interface ChallengeCompProps {
  jwt: string
  item: Challenge
  index: number,
  user: User
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    analysisLink: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      cursor: "grabbing"
    },
  })
);

export default function ChallengeComp(props: ChallengeCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();

  const mapCycle = props.item.map_cycle.split(',');

  const today = moment(new Date());
  const mapStart = moment(props.item.map_start_date).add(props.item.map_cycle_duration, 'days');
  // const mapDaysLeft = mapStart.diff(today, 'days');
  const timeleft = moment.duration(mapStart.diff(today));

  const [demos, set_demos] = React.useState<DemoWaitlistData[]>([]);
  const [loading, set_loading] = React.useState(false);
  const [expanded, set_expanded] = React.useState(props.item.end_date === null);

  React.useEffect(() => {
    fetch(`${API_URL}/challenges/${props.item.id}/demos`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        set_demos(await response.json());
      }
    }).catch((reason) => {
      console.error(reason);
    });
  }, [props.jwt, props.item]);

  const activeMapIndex = mapCycle.indexOf(props.item.map);
  const filteredMapCycle = mapCycle.filter((_, index) => index <= activeMapIndex);

  return <Accordion
    style={{
      backgroundColor: theme.palette.background.default,
    }}
    expanded={expanded}
    onChange={() => set_expanded(old => !old)}
  >
    <AccordionSummary
      expandIcon={
        <ExpandMoreIcon/>
      }
    >
      <div
        style={{
          display: "flex",
          gap: theme.spacing(.5)
        }}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress size="5rem" color="secondary"/>
        </Backdrop>

        <Typography>
          {props.index}. {challengeTypeToStr(props.item.type)}
        </Typography>

        {props.item.end_date === null &&
          <Typography>
            - {t("demo.table.columns.map")} <MapLinkComp
              jwt={props.jwt}
              map={props.item.map}
              set_downloading={(state) => set_loading(state)}
              on_unauthorized={() => {}}
            /> -
          </Typography>
        }

        {props.item.end_date === null &&
          <Typography
            style={{
              color: (timeleft.days() === 0 && timeleft.hours() === 0) ? "red" : "currentcolor"
            }}
          >
            {timeleft.days() === 0 && timeleft.hours() === 0 && timeleft.minutes() <= 0
              ? t('challenge.list.new_map_shortly')
              : t('challenge.list.time_left',  { days: timeleft.days(), hrs: timeleft.hours(), min: timeleft.minutes()})
            }
          </Typography>
        }

        {props.item.winner && <>
          <Typography>
            {t("challenge.list.winners")}
          </Typography>
          
          <AuthorLink small avatar author={props.item.winner} steam_id={props.item.winner.steamID}/>
          {props.item.winner2 && <AuthorLink small avatar author={props.item.winner2} steam_id={props.item.winner2.steamID}/>}
          {props.item.winner3 && <AuthorLink small avatar author={props.item.winner3} steam_id={props.item.winner3.steamID}/>}
        </>}
      </div>
    </AccordionSummary>

    <AccordionDetails
      style={{
        justifyContent: "space-around"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        <Tooltip arrow title={
          <Typography>
            {t(challengeTypeToTranslationKey(props.item.type)).substr(2)}
          </Typography>
        }>
          <HelpIcon/>
        </Tooltip>

        <Timeline>
          {filteredMapCycle.reverse().map((map, mapIndex) =>
            <TimelineItem key={mapIndex}>
              <TimelineOppositeContent style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                paddingTop: theme.spacing(2),
              }}>
                <Typography>
                  <MapLinkComp
                    jwt={props.jwt}
                    map={map}
                    set_downloading={(state) => set_loading(state)}
                    on_unauthorized={() => {}}
                  />
                </Typography>
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot
                  color="inherit"
                >
                  <Typography>
                    {`${filteredMapCycle.length - mapIndex}/${mapCycle.length} `}
                  </Typography>
                </TimelineDot>

                {mapIndex !== filteredMapCycle.length - 1 && filteredMapCycle.length !== 1 && <TimelineConnector/>}
              </TimelineSeparator>

              <TimelineContent>
                {demos.filter(d => d.map.toLowerCase() === map.toLowerCase()).length === 0 &&
                  <Typography>
                    {t("challenge.list.no_demos")}
                  </Typography>
                }

                <List disablePadding>
                  {demos.filter(d => d.map.toLowerCase() === map.toLowerCase())
                        .sort((a, b) => a.time < b.time ? -1 : 1)
                        .map((demo, demoIndex) =>
                    <ListItem key={`${map}_${demoIndex}`}>
                      <ListItemText primary={
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            alignItems: "baseline",
                            gap: theme.spacing(4),
                            justifyItems: "stretch"
                          }}
                        >
                          <Tooltip title={`${t("challenge.points")}`}>
                            <Typography>
                              {playerPositionInTopToPoints(demoIndex)}
                            </Typography>
                          </Tooltip>

                          <AuthorLink author={demo.uploader as User} steam_id={demo.uploader!.steamID} avatar small/>

                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              gap: theme.spacing(2),
                              justifyItems: "stretch"
                            }}
                          >
                            <DemoTimeLinkComp
                              jwt={props.jwt}
                              demo={demo}
                              on_unauthorized={() => {}}
                              set_downloading={(state) => set_loading(state)}
                            />

                            <ViewDemoLink
                              demo={demo}
                              jwt={props.jwt}
                            />

                            <AnalyzeLink
                              demo={demo}
                              user={props.user}
                            />
                          </div>
                        </div>
                      }/>
                    </ListItem>
                  )}
                </List>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </div>
    </AccordionDetails>
  </Accordion>;
};
