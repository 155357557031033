import React, { CSSProperties } from "react";
import { UnregisteredUser } from "../../server/ServerRecord";
import { NEW_API_URL } from "../../Config";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Avatar, Backdrop, Button, Checkbox, Chip, CircularProgress, TextField, Theme, Tooltip, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import RecordFileData from "../../viewdemo/RecordFileData";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as RWList } from 'react-window';
import MapLinkComp from "../MapLinkComp";
import ViewMapLinkComp from "./ViewMapLinkComp";
import MapDownloadLinkComp from "../MapDownloadLinkComp";
import AuthorLink from "../../user/AuthorLink";
import { formatDate, formatMS } from "../../Util";
import ViewDemoLink from "../../viewdemo/ViewDemoLink";
import { useTranslation } from "react-i18next";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface ServerRecordingsV2Props {
    jwt: string | null,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        option: {
            fontSize: 15,
            '& > span': {
                marginRight: 10,
                fontSize: 18,
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fffffff',
        },
        itemsWrapper: {
            display: "grid",
            alignItems: "center",
        },
        itemWrapper: {
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(1)
        },
    })
);

const cameraToStr = (camera: number) => {
    switch (camera) {
        case 1: return "3rd person";
        case 2: return "top down";

        default: return "";
    }
};

export default function ServerRecordingsV2(props: ServerRecordingsV2Props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const [loading, set_loading] = React.useState(true);

    const [players, set_players] = React.useState<UnregisteredUser[]>([]);
    const [selectedPlayers, set_selectedPlayers] = React.useState<UnregisteredUser[]>([]);

    const [playerMaps, set_playerMaps] = React.useState<string[]>([]);
    const [selectedMaps, set_selectedMaps] = React.useState<string[]>([]);

    const RowHeight = 75;

    React.useEffect(() => {
        set_loading(true);

        fetch(`${NEW_API_URL}/server/recordings/players`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${props.jwt}`
            }
        }).then(async (response) => {
            if (response.status !== 200) {

            } else {
                set_players(((await response.json() as UnregisteredUser[])
                    .filter(usr => usr.steamID.length !== 0))
                    .map(p => { return { ...p, countryCode: p.countryCode ? p.countryCode : "N/A" }; })
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                    .sort((a, b) => {
                        if (a.countryCode === "N/A") {
                            return 1;
                        }

                        return a.countryCode.localeCompare(b.countryCode);
                    })
                );
            }
        }).finally(() => {
            set_loading(false);
        });
    }, [props]);


    React.useEffect(() => {
        if (selectedPlayers.length === 0) {
            return;
        }

        set_loading(true);
        set_selectedMaps([]);
        set_playerRecordings([]);

        fetch(`${NEW_API_URL}/server/recordings/players_maps`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${props.jwt}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(selectedPlayers.map(p => p.steamID))
        }).then(async (response) => {
            if (response.status !== 200) {

            } else {
                set_playerMaps(await response.json());
            }
        }).finally(() => {
            set_loading(false);
        });
    }, [selectedPlayers, props.jwt]);

    const [playerRecordings, set_playerRecordings] = React.useState<RecordFileData[]>([]);

    const [sortField, set_sortField] = React.useState("date");
    const [sortAscending, set_sortAscending] = React.useState(false);

    const getSortingIcon = React.useCallback((field: string) => {
        if (field !== sortField) {
            return null;
        }

        if (sortAscending) {
            return <ArrowUpwardIcon />;
        }

        return <ArrowDownwardIcon />;
    }, [sortAscending, sortField]);

    const sortedPlayerRecordings = React.useMemo(() => {
        return playerRecordings.sort((a, b) => {
            const factor = (sortAscending ? 1 : -1);

            if (sortField === "map") {
                return a.map.localeCompare(b.map) * factor;
            }

            if (sortField === "weapon") {
                return a.weapon.localeCompare(b.weapon) * factor;
            }

            if (sortField === "time") {
                return (a.time < b.time ? -1 : 1) * factor;
            }

            return (a.upload_date < b.upload_date ? -1 : 1) * factor;
        });
    }, [playerRecordings, sortAscending, sortField]);

    const updateRecords = (players: UnregisteredUser[], maps: string[], jwt: string | null) => {
        if (players.length === 0) {
            return;
        }

        if (maps.length === 0) {
            set_playerRecordings([]);
            return;
        }

        set_loading(true);

        fetch(`${NEW_API_URL}/server/recordings/list`, {
            method: "POST",
            body: JSON.stringify({
                maps: maps,
                players: players.map(p => p.steamID)
            }),
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Content-type": "application/json"
            }
        }).then(async (response) => {
            if (response.status !== 200) {

            } else {
                set_playerRecordings(await response.json());
            }
        }).finally(() => {
            set_loading(false);
        });
    };

    const onMapsMenuClose = React.useCallback(() => {
        updateRecords(selectedPlayers, selectedMaps, props.jwt);
    }, [selectedPlayers, selectedMaps, props.jwt]);

    const getUser = React.useCallback((sid: string) => {
        return selectedPlayers.find(sp => sp.steamID === sid);
    }, [selectedPlayers]);

    const Row = (input: { index: number, style: CSSProperties }) => {
        if (selectedPlayers.length === 0) {
            return null;
        }

        const data = sortedPlayerRecordings[input.index];

        const user = getUser(data.fileName);

        if (!user) {
            return null;
        }

        return <div
            className={classes.itemsWrapper}
            style={{
                gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
                height: `${RowHeight}px`,
                ...input.style,
            }}
        >
            <div className={classes.itemWrapper}>
                <MapLinkComp
                    jwt={props.jwt}
                    map={data.map}
                    route={(!data.route || data.route.length === 0) ? undefined : data.route}
                    on_unauthorized={() => { }}
                    set_downloading={set_loading}
                />

                <ViewMapLinkComp
                    map={data.map}
                    jwt={props.jwt}
                    on_unauthorized={() => { }}
                    set_downloading={set_loading}
                />

                <MapDownloadLinkComp
                    icon
                    hideText
                    map={data.map}
                    jwt={props.jwt}
                    set_downloading={set_loading}
                    on_unauthorized={() => { }}
                />
            </div>

            <div className={classes.itemWrapper}>
                <AuthorLink author={user} steam_id={user.steamID} avatar />

                {data.aa !== 10 &&
                    <Typography variant="overline">
                        AA {data.aa}
                    </Typography>
                }

                {data.camera !== 0 &&
                    <Typography variant="overline">
                        {data.aa !== 10 && ' | '}{cameraToStr(data.camera)}
                    </Typography>
                }
            </div>

            <div className={classes.itemWrapper}>
                <Tooltip title={formatDate(data.upload_date)}>
                    <Typography>
                        {formatMS(data.time)}
                    </Typography>
                </Tooltip>

                <ViewDemoLink
                    jwt={props.jwt}
                    serverRecord
                    demo={{
                        demoMD5: data.md5,
                        map: data.map,
                        player: user.displayName,
                        time: data.time
                    }}
                />
            </div>

            <div className={classes.itemWrapper}>
                <Typography>
                    {data.weapon}
                </Typography>
            </div>

            {data.gc === 0
                ?
                <div className={classes.itemWrapper}>
                    <Chip
                        label="Pro"
                        color="primary"
                    />
                </div>
                :
                <div className={classes.itemWrapper}>
                    <Typography>
                        {data.cp} / {data.gc}
                    </Typography>
                </div>
            }
        </div>;
    };

    return <div>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress size="5rem" color="secondary" />
        </Backdrop>

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: theme.spacing(2),
                alignItems: "center",
                gap: theme.spacing(6)
            }}
        >
            {players.length !== 0 &&
                <Autocomplete
                    multiple
                    style={{ width: "90%" }}
                    options={players}
                    classes={{
                        option: classes.option,
                    }}
                    onChange={(_, newValues) => set_selectedPlayers(newValues)}
                    autoHighlight
                    groupBy={(option) => option.countryCode ? option.countryCode : ""}
                    getOptionLabel={(option) => option.displayName}
                    getOptionSelected={(option, value) => {
                        return option.steamID === value.steamID;
                    }}
                    renderGroup={(params) => {
                        return <div
                            style={{
                                paddingTop: theme.spacing(2)
                            }}
                            key={params.key}
                        >
                            {params.group !== "N/A"
                                ? <ReactCountryFlag style={{ fontSize: "1.5em" }} countryCode={params.group} />
                                : "N/A"}
                            {params.children}
                        </div>;
                    }}
                    renderOption={(option) => (
                        <div
                            style={{
                                color: "white",
                                display: "flex",
                                gap: theme.spacing(1),
                                alignItems: "center"
                            }}
                            key={option.steamID}
                        >
                            <Avatar
                                alt="avatar"
                                src={option.avatar.large}
                                style={{
                                    width: theme.spacing(3),
                                    height: theme.spacing(3),
                                }}
                            />

                            {option.displayName}
                        </div>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("server_records.pick_players")}
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="default"
                                label={<div
                                    style={{
                                        color: "white",
                                        display: "flex",
                                        gap: theme.spacing(1),
                                        alignItems: "center",
                                        backgroundColor: theme.palette.divider,
                                        paddingRight: theme.spacing(1),
                                        borderRadius: theme.spacing(10)
                                    }}
                                >
                                    <Avatar
                                        alt="avatar"
                                        src={option.avatar.large}
                                        style={{
                                            width: theme.spacing(3),
                                            height: theme.spacing(3),
                                        }}
                                    />

                                    {option.displayName}
                                </div>}
                                size="medium"
                                {...getTagProps({ index })}
                                clickable={false}
                            />
                        ))
                    }
                />
            }

            {playerMaps.length !== 0 &&
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                        paddingBottom: theme.spacing(6)
                    }}
                >
                    <Autocomplete
                        multiple
                        style={{
                            width: "90%",
                        }}
                        value={selectedMaps}
                        options={playerMaps}
                        disableCloseOnSelect
                        limitTags={12}
                        onChange={(_, newValues) => set_selectedMaps(newValues)}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label={t("server_records.maps_capital")} />
                        )}
                        onClose={() => onMapsMenuClose()}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </React.Fragment>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="default"
                                    label={option}
                                    size="medium"
                                    {...getTagProps({ index })}
                                    clickable={false}
                                    onDelete={undefined}
                                />
                            ))
                        }
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: theme.spacing(2)
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (playerMaps.length === selectedMaps.length) {
                                    set_selectedMaps([]);
                                    updateRecords(selectedPlayers, [], props.jwt);
                                } else {
                                    set_selectedMaps(playerMaps);
                                    updateRecords(selectedPlayers, playerMaps, props.jwt);
                                }
                            }}
                        >
                            {playerMaps.length === selectedMaps.length
                                ? t("server_records.unselect_all")
                                : t("server_records.select_all")}
                        </Button>
                    </div>
                </div>
            }
        </div>

        {playerRecordings.length !== 0 &&
            <>
                <div
                    className={classes.itemsWrapper}
                    style={{
                        gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
                        paddingBottom: theme.spacing(4),
                    }}
                >
                    {[
                        { field: "map", label: t("demo.table.columns.map"), sortable: true },
                        { field: "player", label: t("demo.table.columns.player"), sortable: true },
                        { field: "time", label: t("demo.table.columns.time"), sortable: true },
                        { field: "weapon", label: t("demo.table.columns.weapon"), sortable: true },
                        { field: "cp/gc", label: "CP/GC", sortable: false },
                    ].map(({ field, label, sortable }, index) =>
                        <div
                            key={index}
                            className={classes.itemWrapper}
                            onClick={() => {
                                if (sortable) {
                                    set_sortField(field);
                                    set_sortAscending(!sortAscending);
                                }
                            }}
                        >
                            <Typography>
                                {label}
                            </Typography>

                            {sortable && getSortingIcon(field)}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        minHeight: Math.min(RowHeight * sortedPlayerRecordings.length, 1800),
                    }}
                >
                    <AutoSizer>
                        {(param: { width: number }) => (
                            <RWList
                                height={Math.min(RowHeight * sortedPlayerRecordings.length, 1800)}
                                width={param.width}
                                itemSize={RowHeight}
                                itemCount={sortedPlayerRecordings.length}
                            >
                                {Row}
                            </RWList>
                        )}
                    </AutoSizer>
                </div>
            </>
        }
    </div>;
}
